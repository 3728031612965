#profile-shop{
.image-shop{
  width: 64px;
  height: 64px;
  border-radius: 50px;
  background-color: #ECECEC;
  overflow: hidden;
  i{
    color: #ffffff;
    font-size:30px
  }
  img{
    width: 64px;
    height: 64px;
  }
}
}