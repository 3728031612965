@import 'scss/variables';

#toast-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 10000000;
  .toast-item {
    width: 25rem;
    min-height: 3rem;
    margin-bottom: 1rem;
    padding: 0.75rem 1rem;
    border-radius: 0.25rem;
    border-left: 4px solid;
    background-color: white;
    box-shadow: $elevations-24;
    transition: all 0.5s ease;
    animation: fadeIn 0.2s linear, fadeOut 0.5s linear 2.5s forwards;

    display: flex;

    @keyframes fadeIn {
      from {
        transform: translateX(27rem);
      }
      to {
        transform: translateX(0);
      }
    }

    @keyframes fadeOut {
      from {
        transform: translateX(0rem);
      }
      to {
        transform: translateX(27rem);
      }
    }

    .toast-item__icon {
      width: 2rem;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: inherit;
      i {
        width: 1.6rem;
        height: 1.6rem;
        font-size: 1rem;
        border-radius: 50%;
        border: 2px solid;
        border-color: inherit;
        color: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .toast-item__content {
      flex: 1;
      display: flex;
      align-items: center;
    }
    .toast-item__close {
      width: 2.5rem;
      height: 3rem;
      margin: -0.75rem -1rem -0.75rem 0.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}
