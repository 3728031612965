@import 'scss/variables';
.h-signup-seller {
  .h-ss-header {
    height: 5rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    background-color: $base-color;
    display: flex;
    justify-content: center;

    color: white;
    .h-ss__logo {
      font-size: 2rem;
      font-weight: bold;
    }
    .h-ss__title {
      margin-left: 3rem;
      font-size: 1.7rem;
    }
  }
  .h-ss-container {
    .h-step-container {
      margin-top: 2rem;
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      .h-step__item {
        transition: all 0.4s ease;
        &.past {
          .h-step__num,
          .h-step__name {
            border-color: $base-color;
            color: $base-color;
          }
        }
        &.current {
          .h-step__num {
            border-color: $base-color;
            color: white;
            background-color: $base-color;
          }

          .h-step__name {
            color: $base-color;
          }
        }

        .h-step__num {
          width: 2.5rem;
          height: 2.5rem;
          border: 2px solid $gray-500;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
          color: $gray-500;
          margin: 0 auto;

          transition: all 0.4s ease;
        }
        .h-step__name {
          margin-top: 0.5rem;
          font-size: 1.1rem;
          color: $gray-500;

          transition: all 0.4s ease;
        }
      }
      .h-step__arrow {
        width: 8rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &.past {
          .h-arrow {
            background-color: $base-color;
            &::after {
              border-color: $base-color $base-color transparent transparent;
            }
          }
        }
        .h-arrow {
          position: relative;
          width: 6rem;
          height: 2px;
          background-color: $gray-500;
          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: -5px;
            width: 12px;
            height: 12px;
            border: 2px solid;
            border-color: $gray-500 $gray-500 transparent transparent;
            transform: rotate(45deg);
          }
        }
      }
    }

    .h-ss-content {
      height: 90vh;
      width: 31rem;
      display: flex;
      padding: 0.5rem;
      margin: 0 auto;
      overflow: hidden;
      .h-signup__title {
        width: 50%;
        padding-top: 5rem;
        display: flex;
        justify-content: center;
        color: #ffffff;
        font-weight: bold;
        font-size: 5rem;
        .h-signup__brand {
          height: 12rem;
          padding-right: 1.5rem;
          margin-right: 1.5rem;
          line-height: 5rem;
          border-right: 0.25rem solid white;
        }
        .h-signup__slogan {
          font-size: 2.5rem;
          width: 150px;
        }
      }
      .form-container {
        display: flex;
        transition: all 0.4s ease;
        &.toSlide-40 {
          transform: translateX(-40rem);
        }
        &.toSlide-80 {
          transform: translateX(-80rem);
        }
      }
      .h-signup__from {
        width: 30rem;
        height: 24rem;
        padding: 2rem;
        margin-right: 10rem;
        background-color: white;
        border-radius: 0.5rem;
        box-shadow: $elevations-4;
      }
    }
  }
}
