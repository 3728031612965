#page-right{
  margin-top: 20px;
  .title-page{
    .parent{
      font-size: 30px;
      color: #000000;
      p{
        margin: 0;
      }
    }
    .children{
      margin-left: 8px;
      font-size: 20px;
      i{
        font-size: 15px;
        margin-right: 8px;
      }
    }
  }
}