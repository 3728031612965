#product-list {
  padding: 20px 20px;
  background-color: #ffffff;
  width: 1000px;
  margin-top: 20px;
  height: 700px;
  overflow-y: scroll;
  .title {
    p {
      font-size: 25px;
      color: #000000;
    }
  }

  .product {
    margin-bottom: 20px;
    border-bottom: 1px solid #ECECEC;
    padding-bottom: 20px;

    .image-product {
      width: 60px;
      height: 60px;
      border-radius: 4px;
      background-color: #ECECEC;
      overflow: hidden;
      margin-right: 16px;
      img{
        width: 60px;
        height: 60px;
      }
    }

    .name-product {
      width: 200px;
      margin-right: 20px;
      font-size: 18px;
    }

    .btn-nav {
      button {
        background-color: #ffffff;
        height: 40px;
        border: 1px solid #ECECEC;
        border-radius: 4px;

        &:first-child {
          margin-right: 20px;
        }

        &:hover {
          background-color: #0d6efd;
          color: #ffffff;
        }
      }
    }
  }
}
.confirm-delete {
  top: 180px;
  left: 50%;
  background-color: #ffffff;
  width: 300px;
  padding: 16px;
  z-index: 2;
  border-radius: 8px;

  .btn-confirm {
    button {
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid #ECECEC;
      color: #000000;

      &:first-child {
        margin-right: 20px;
      }

      &:hover {
        background-color: #0d6efd;
        color: #ffffff;
      }
    }

  }
}
.edit-product {
  background-color: #ffffff;
  position: fixed;
  top: 20%;
  left: 50%;
  z-index: 2;
  padding: 20px;
  border-radius: 8px;
  p {
    margin-bottom: 0;
  }
  .price{
    margin-top: 20px;
  }
  .btn-confirm {
    margin-top: 20px;

    button {
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid #ECECEC;
      color: #000000;

      &:hover {
        background-color: #0d6efd;
        color: #ffffff;
      }
    }
  }
}