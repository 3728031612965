#warehouse-detail{
  .btn-nav{
    button{
      background-color: #ffffff;
      margin-right: 20px;
      border: 1px solid #0d6efd;
      border-radius: 8px;
      padding: 6px 8px;
      &:hover{
        background-color: #0d6efd;
        color: #ffffff;
      }
    }
    a{
      background-color: #ffffff;
      margin-right: 20px;
      border: 1px solid #0d6efd;
      border-radius: 8px;
      padding: 6px 8px;
      &:hover{
        background-color: #0d6efd;
        color: #ffffff;
      }
    }
  }
  .product{
    width: 500px;
    margin-bottom: 20px;
    p{
      margin: 0;
    }
    .img-product{
      width: 80px;
      height: 80px;
      background-color: #4f5d73;
      overflow: hidden;
      margin-right: 20px;
      border-radius: 8px;
      img{
        width: 80px;
        height: 80px;
      }
    }
  }
}