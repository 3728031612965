@import 'scss/variables';

.h-main {
  margin-top: 7rem;
  min-height: 80vh;
  .h-main-container {
    width: 1200px;
    margin: 0 auto;
  }
}

.h-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 7rem;
  color: white;
  background-color: $base-color;
  z-index: 10000;
  .h-header-container {
    max-width: 1200px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    .h-header-top {
      display: flex;
      justify-content: space-between;
      height: 2rem;

      &__left,
      &__right {
        display: flex;
      }
      &__item {
        display: flex;
        align-items: center;
        height: 2rem;
      }
      &__link {
        padding: 0 0.5rem;
      }
      .h-header-top__item + .h-header-top__item > .h-header-top__link {
        border-left: 2px solid $gray-400;
      }

      .h-dropdown--noti {
        &:hover .h-dropdown__toggle {
          opacity: 0.8;
        }

        .h-dropdown__menu {
          top: 2rem;
          right: 0;
          width: 20rem;
          height: 25rem;
          transform-origin: 18.5rem top;
          &::before {
            left: 18rem;
          }
        }
      }

      .h-dropdown--profile {
        &:hover .h-dropdown__toggle {
          opacity: 0.8;
        }

        .h-dropdown__menu {
          top: 2rem;
          right: 0;
          width: 10rem;
          transform-origin: 50% top;
          &::before {
            left: calc(50% - 0.5rem);
          }
        }
        .h-menu__item {
          &:hover {
            color: $base-color;
          }
        }
      }
    }

    .h-header-main {
      display: flex;
      margin-top: 1.25rem;
      .h-header__brand {
        flex: 0 0 auto;
        width: 12rem;
        padding: 0 0 0 0.5rem;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 2.5rem;
        cursor: pointer;
      }
      .h-header__search {
        display: flex;
        flex: 1 1 auto;
        height: 2.5rem;
        .h-search__input {
          appearance: none;
          width: 100%;
          padding: 0 1rem;
          border: none;
          border-radius: 0.25rem;
          box-shadow: $elevations-4;
          &:focus {
            outline: none;
          }
          &:hover {
            background-color: $gray-100;
          }
        }
        .h-search__btn {
          appearance: none;
          margin-left: 0.5rem;
          padding: 0 0.75rem;
          border: none;
          background-color: $white;
          border-radius: 0.25rem;

          &:hover {
            background-color: $gray-100;
          }
        }
      }
      .h-header__cart {
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        align-items: flex-end;
        width: 10rem;
        height: 3rem;

        .h-cart__icon {
          position: relative;
          bottom: 0.5rem;
          font-size: 2rem;
          line-height: 2rem;
          cursor: pointer;

          &:hover {
            color: $gray-200;
          }
          .h-cart__amount {
            position: absolute;
            text-align: center;
            min-width: 1.5rem;
            top: -0.5rem;
            left: 1.5rem;
            padding: 0.2rem;
            font-size: 1rem;
            line-height: 1rem;
            border-radius: 0.75rem;
            color: $base-color;
            background-color: $white;
            box-shadow: $elevations-4;
          }
        }

        .h-dropdown--cart {
          .h-dropdown__menu {
            right: 0;
            width: 25rem;
            height: 25rem;
            transform-origin: 24rem top;
            &::before {
              right: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.h-footer {
  color: $white;
  background-color: $base-color;
  .h-footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
}

.h-dropdown {
  position: relative;
  cursor: pointer;

  &:hover .h-dropdown__menu {
    display: block;
  }

  &__menu {
    display: none;
    position: absolute;
    border-radius: 0.25rem;
    color: $black;
    background-color: $white;
    box-shadow: $elevations-4;
    animation: h-a-fade-in 0.2s ease-out;
    z-index: 1000;

    &::before {
      content: '';
      position: absolute;
      top: -0.5rem;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid $white;
    }
  }
  .h-menu__item {
    padding: 0.5rem 1rem;
  }
}

@keyframes h-a-fade-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
