#forgot-password{
width: 100%;
  .header-forgot{
    font-size: 20px;
  }
  .form-forgot{
    .user-name{
      input{
        border-radius: 8px;
        padding: 4px 16px;
        outline: none;
        border: none;
        width: 100%;
        &:focus{
          border: 1px solid #00b14f;
          outline: none;
        }
      }
    }
  }
  .btn{
    button{
      width: 120px;
      height: 40px;
      color: #00b14f;
      border-radius: 8px;
      border: 1px solid #00b14f;
      &:hover{
        background-color: #00b14f;
        color: #ffffff;
      }
      &:first-child{
        margin-right: 8px;
      }
    }
  }
}