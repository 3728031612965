body {
  background-color: $gray-100;
}
a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    opacity: 0.8;
  }
}

.text-base-color {
  color: $base-color !important;
}

.bg-base-color {
  background-color: $base-color !important;
}

.border-base-color {
  border-color: $base-color !important;
}

.btn {
  box-shadow: $elevations-3;
  border: none;
  transition: all 0.2s linear;
}
.btn:hover {
  box-shadow: $elevations-6 !important;
  opacity: 0.9;
}
.form-control {
  border-color: $base-color !important;
}
.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 177, 77, 0.2);
}

.elevation-4 {
  box-shadow: $elevations-4;
}
.elevation-2 {
  box-shadow: $elevations-2;
}
