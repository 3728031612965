$base-color: #00b14f;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

$primary-base: #321fdb !default;
$primary-100: #eae9fb !default;
$primary-200: #d6d2fb !default;
$primary-300: #c1bcf4 !default;
$primary-400: #ada5f1 !default;
$primary-500: #988fed !default;
$primary-600: #8478ea !default;
$primary-700: #6f62e6 !default;
$primary-800: #5b4ce2 !default;
$primary-900: #4635df !default;

$primary-dark: #1f1498 !default;
$primary: #321fdb !default;
$primary-50: #988fed !default;
$primary-25: #ccc7f6 !default;

$secondary-base: #3c4b64 !default;

$secondary-100: #ebedef !default;
$secondary-200: #d8dbe0 !default;
$secondary-300: #c4c9d0 !default;
$secondary-400: #b1b7c1 !default;
$secondary-500: #9da5b1 !default;
$secondary-600: #8a93a2 !default;
$secondary-700: #768192 !default;
$secondary-800: #636f83 !default;
$secondary-900: #4f5d73 !default;

$secondary-dark: #212233 !default;
$secondary: #3c4b64 !default;
$secondary-50: #9da5b1 !default;
$secondary-25: #ced2d8 !default;

$danger-dark: #d93737 !default;
$danger: #e55353 !default;
$danger-50: #f2a9a9 !default;
$danger-25: #f9d4d4 !default;

$info-dark: #2982cc !default;
$info: #39f !default;
$info-50: #80c6ff !default;
$info-25: #c0e6ff !default;

$success-dark: #1b9e3e !default;
$success: #2eb85c !default;
$success-50: #96dbad !default;
$success-25: #cbedd6 !default;

$warning-dark: #f6960b !default;
$warning: #f9b115 !default;
$warning-50: #fcd88a !default;
$warning-25: #feecc5 !default;

$pink: #e83e8c !default;

$elevations-0: none;
$elevations-1: 0 1px 1px 0 rgba(60, 75, 100, 0.14), 0 2px 1px -1px rgba(60, 75, 100, 0.12),
  0 1px 3px 0 rgba(60, 75, 100, 0.2);
$elevations-2: 0 2px 2px 0 rgba(60, 75, 100, 0.14), 0 3px 1px -2px rgba(60, 75, 100, 0.12),
  0 1px 5px 0 rgba(60, 75, 100, 0.2);
$elevations-3: 0 3px 4px 0 rgba(60, 75, 100, 0.14), 0 3px 3px -2px rgba(60, 75, 100, 0.12),
  0 1px 8px 0 rgba(60, 75, 100, 0.2);
$elevations-4: 0 4px 5px 0 rgba(60, 75, 100, 0.14), 0 1px 10px 0 rgba(60, 75, 100, 0.12),
  0 2px 4px -1px rgba(60, 75, 100, 0.2);
$elevations-6: 0 6px 10px 0 rgba(60, 75, 100, 0.14), 0 1px 18px 0 rgba(60, 75, 100, 0.12),
  0 3px 5px -1px rgba(60, 75, 100, 0.2);
$elevations-8: 0 8px 10px 1px rgba(60, 75, 100, 0.14), 0 3px 14px 2px rgba(60, 75, 100, 0.12),
  0 5px 5px -3px rgba(60, 75, 100, 0.2);
$elevations-9: 0 9px 12px 1px rgba(60, 75, 100, 0.14), 0 3px 16px 2px rgba(60, 75, 100, 0.12),
  0 5px 6px -3px rgba(60, 75, 100, 0.2);
$elevations-12: 0 12px 17px 2px rgba(60, 75, 100, 0.14), 0 5px 22px 4px rgba(60, 75, 100, 0.12),
  0 7px 8px -4px rgba(60, 75, 100, 0.2);
$elevations-16: 0 16px 24px 2px rgba(60, 75, 100, 0.14), 0 6px 30px 5px rgba(60, 75, 100, 0.12),
  0 8px 10px -5px rgba(60, 75, 100, 0.2);
$elevations-24: 0 24px 38px 3px rgba(60, 75, 100, 0.14), 0 9px 46px 8px rgba(60, 75, 100, 0.12),
  0 11px 15px -7px rgba(60, 75, 100, 0.2);
